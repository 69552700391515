import { Barricas1, Barricas2, Barricas3, Barricas4, Barricas5, Barricas6 } from "../images/productos/barricas"

export const Barricas = [{
    size: 0,
    name: "Barrica de Roble Frances - 225L",
    src: Barricas1,

}, {
    size: 0,
    name: "Barrica de Roble Frances - 228L",
    src: Barricas2,

}, {
    size: 0,
    name: "Barrica de Roble Frances - 300L",
    src: Barricas3,

}, {
    size: 0,
    name: "Barrica de Roble Frances - 360L",
    src: Barricas4,

}, {
    size: 0,
    name: "Barrica de Roble Frances - 400L",
    src: Barricas5,

}, {
    size: 0,
    name: "Barrica de Roble Frances -500L",
    src: Barricas6,

}]