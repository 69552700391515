import React from "react";

import Products from "../products";
import ProductCard from "../../components/ProductCards/ProductCards";
import SEO from "../../components/SEO/Seo";

import { Barricas } from "../../data/barricas";

const BarricasOrion = () => {
  return (
    <>
      <SEO
        title="Blue Sky SA - Barricas de Orion"
        keywords="Blue Sky SA ,Barricas de Orion"
        description="Blue Sky SA - Barricas de Orion"
      />
      <Products subtitle="Barricas de Orion">
        <ProductCard data={Barricas} />
      </Products>
    </>
  );
};

export default BarricasOrion;
